/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React from "react";
import { ArcElement, Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(...registerables, ChartDataLabels);

const plugin = {
  id: "total",
  afterDraw: (chart: any) => {
    const { width, height, ctx } = chart;
    ctx.restore();
    ctx.font = "3vmax sans-serif";
    ctx.textBaseline = "middle";

    const values = chart.config.data.labels.map((l: string) =>
      parseInt(l.split("(")[1].replace(")", "")),
    );
    const text =
        values.length > 0
          ? `Total (${values.reduce((v1: number, v2: number) => v1 + v2)})`
          : 0,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = (height - chart.legend.height) / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};
const LegendMarginPlugin = {
  id: "LegendMarginPlugin",
  beforeInit(chart: any) {
    const fitValue = chart.legend.fit;

    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();

      let top: number;
      const marginTop = 50;

      Object.defineProperty(this, "top", {
        get() {
          return top + marginTop;
        },
        set(v) {
          top = v;
        },
      });
      this.height += 40;
    };
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "60%",
  onHover: (evt: any, item: any) => {
    if (item.length) {
      evt.chart
        .getDatasetMeta(0)
        .data.forEach((arc: ArcElement, index: number) => {
          arc.active = item[0].index === index;
          if (item[0].index !== index) {
            if (!String(arc.options.backgroundColor).endsWith("0D")) {
              arc.options.backgroundColor += "0D";
              arc.options.borderColor += "0D";
            }
          }
        });
    } else {
      evt.chart.getDatasetMeta(0).data.forEach((arc: ArcElement) => {
        arc.active = false;
        if (String(arc.options.backgroundColor).endsWith("0D")) {
          arc.options.backgroundColor = String(
            arc.options.backgroundColor,
          ).slice(0, -2);
          arc.options.borderColor = String(arc.options.borderColor).slice(
            0,
            -2,
          );
        }
      });
    }
  },
  plugins: {
    legend: {
      position: "bottom" as const,
      useBorderRadius: true,
      labels: {
        font: {
          size: 24,
          weight: 600,
        },
      },
      // onClick: (evt: any, item: any, legend: any) => {
      //   const currentColor = legend.chart.data.datasets[0].backgroundColor[item.index];
      //   legend.chart.data.datasets[0].backgroundColor.forEach((color: any, index: any, colors: any) => {
      //     if (colors[index].includes('0D')) {
      //       colors[index] = color.slice(0, -2);
      //     } else {
      //       colors[index] = index === item.index ? color : color + '0D';
      //     }
      //     legend.chart.data.datasets[0].borderColor[index] = colors[index]
      //   });
      //   legend.chart.update();
      // }
    },
    datalabels: {
      display: true,
      color: "black",
      font: {
        size: 20,
      },
      padding: 6,
      formatter: (value: number, context: any) => {
        return value + "%";
      },
    },
    // tooltip: {
    //   enabled: false
    // },
  },
};

export type DoughnutDataSetType = {
  label: string;
  data: number[];
  borderColor: string[];
  backgroundColor: string[];
};

export type DoughnutData = {
  labels: string[];
  datasets: DoughnutDataSetType[];
};

type DoughnutChartProps = {
  data: DoughnutData;
};

const DoughnutChartJS = (props: DoughnutChartProps) => {
  const { data } = props;
  return (
    <Doughnut
      options={options}
      plugins={[plugin, LegendMarginPlugin]}
      data={data}
    />
  );
};
export default React.memo(DoughnutChartJS);
