import {
  ChangeEvent,
  FormEvent,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import AppContext from "src/components/AppContext";
import Dashboard from "./dashboard";
import { useRouter } from "next/router";
import { alertService } from "src/services";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
} from "firebase/auth";
import { auth } from "helpers/firebase";
import { graphqlApiCall } from "src/services/graphql.service";
import BackgroundImage from "public/images/Login_Page_Background.jpg";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import Image from "next/image";

interface ContactData {
  mobile_number: string;
}

const Home = () => {
  const context = useContext(AppContext);
  const { currentUser, setShowLoader } = context.state;
  const router = useRouter();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>();
  const [data, setData] = useState<ContactData>({
    mobile_number: "",
  });
  const [showMobileLogin, setShowMobileLogin] = useState<boolean>(false);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev) {
      const { name } = ev.currentTarget;
      let value = ev.currentTarget.value;
      if (name === "mobile_number") {
        value = value.replace(/[^0-9]/g, "");
        if (value.length > 10) return;
      }
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const setRecaptcha = () => {
    if (setShowLoader) setShowLoader(false);
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
    );
  };

  const onVerificationCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev) {
      const value = ev.currentTarget.value.replace(/[^0-9]/g, "");
      if (value.length > 6) return;
      setVerificationCode(value);
    }
  };
  const handleSendCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setShowLoader) setShowLoader(true);
    setRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+91${data.mobile_number}`,
        appVerifier,
      );
      setConfirmResult(confirmationResult);
    } catch {
      alertService.error("Please enter valid mobile number.");
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };

  const handleVerifyCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!confirmResult) {
      alertService.error("Please enter correct code.");
      return;
    }
    try {
      if (setShowLoader) setShowLoader(true);
      const result = await confirmResult.confirm(verificationCode);
      const user = result.user;
      await handleFormSubmit(user);
    } catch {
      alertService.error("Please enter correct code.");
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await handleFormSubmit(user);
    } catch (error) {
      console.error("Error during Google sign-in: ", error);
    }
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await handleFormSubmit(user);
    } catch (error) {
      console.error("Error during Apple sign-in: ", error);
    }
  };

  const handleFormSubmit = async (user: User) => {
    try {
      if (setShowLoader) setShowLoader(true);
      const response = await graphqlApiCall({
        node: "userByUserId",
        columns: ["userId", "name", "email"],
        id: `userId: "${user.uid}"`,
      });
      let data;
      if (response) {
        data = response;
        await graphqlApiCall({
          node: "updateUserByUserId",
          columns: ["user {", "userId", "}"],
          input: `userPatch: {name: "${user.displayName}"}, userId: "${user.uid}"`,
        });
      } else {
        data = await graphqlApiCall({
          node: "createUser",
          columns: ["user {", "userId", "}"],
          input: `user: {userId: "${user.uid}", name: "${user.displayName}", email: "${user.email}", exitPercentage: 1000000, investedAmount: 0, stopLossPercentage: 7, timeHorizon: 5}`,
        });
      }
      if (setShowLoader) setShowLoader(false);
      localStorage.setItem("userId", data.userId);
      router.push("/consumer");
    } catch (error) {
      alertService.error("Something went wrong. Please try again later.");
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) router.push("/consumer");
  }, [router]);

  if (currentUser) return <Dashboard />;
  return (
    <>
      <div className="col-12 justify-content-center align-items-center min-vh-100 login-page">
        <div className="row  m-0">
          <div
            className="col-12 col-md-6 col-lg-8 d-none d-md-flex align-items-center text-white"
            style={{
              backgroundImage: `url(${BackgroundImage.src})`,
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "0",
            }}
          >
            <div
              className="d-flex flex-column w-100 mt-3"
              style={{ paddingLeft: "9.5%", paddingBottom: "12%" }}
            >
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 12C7.62742 12 12 7.62742 12 0C12 7.62742 16.3726 12 24 12C16.3726 12 12 16.3726 12 24C12 16.3726 7.62742 12 0 12Z"
                  fill="#FEC84B"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64 24C69.0849 24 72 21.0849 72 16C72 21.0849 74.9151 24 80 24C74.9151 24 72 26.9151 72 32C72 26.9151 69.0849 24 64 24Z"
                  fill="#FEC84B"
                />
                <path
                  d="M40.1509 19L8.15095 61.8491L15.6981 59.2842L40 26.5439L59.4717 52.4947L52.9811 50.0807L50.2642 53.8526L72 61.8491L40.1509 19Z"
                  fill="white"
                />
                <path
                  d="M29.8868 49.4772L12.9811 55.2105L8 62L29.8868 54.4561L40.4528 58.8316L60.0755 32.4281L62.9434 34.6912V25.3368L53.5849 27.2982L56.4528 29.4105L38.9434 53.2491L29.8868 49.4772Z"
                  fill="white"
                />
              </svg>
              <h1 className="display-1">Data Driven</h1>
              <h1 className="display-1">Investing</h1>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-md-flex align-items-center justify-content-center bg-white">
            <div className="row p-4">
              <div className="text-center ">
                <div className="mb-3">
                  <Image
                    src="/images/ab-logo.svg"
                    alt="Abillion"
                    height={92}
                    width={136}
                  />
                </div>
                <div className="mb-5">
                  <h5>
                    <em>For everyone&apos;s profit</em>
                  </h5>
                </div>
                <div className="mb-4">
                  <h2>Welcome to Abillion!</h2>
                  <h6 className="text-muted">
                    Your gateway to investing smarter, <br />
                    profit for everyone starts here!
                  </h6>
                </div>
              </div>
              <div className="mb-4" style={{ gap: "20px", display: "grid" }}>
                <button
                  type="button"
                  className="btn btn-outline-dark w-100"
                  onClick={handleGoogleSignIn}
                >
                  <svg
                    width="25"
                    height="35"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_4204_534)">
                      <path
                        d="M24.2663 12.2763C24.2663 11.4605 24.2001 10.6404 24.059 9.83789H12.7402V14.4589H19.222C18.953 15.9492 18.0888 17.2676 16.8233 18.1054V21.1037H20.6903C22.9611 19.0137 24.2663 15.9272 24.2663 12.2763Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12.7401 24.0013C15.9766 24.0013 18.7059 22.9387 20.6945 21.1044L16.8276 18.106C15.7517 18.838 14.3627 19.2525 12.7445 19.2525C9.61388 19.2525 6.95946 17.1404 6.00705 14.3008H2.0166V17.3917C4.05371 21.4439 8.2029 24.0013 12.7401 24.0013Z"
                        fill="#34A853"
                      />
                      <path
                        d="M6.00277 14.3007C5.50011 12.8103 5.50011 11.1965 6.00277 9.70618V6.61523H2.01674C0.314734 10.006 0.314734 14.0009 2.01674 17.3916L6.00277 14.3007Z"
                        fill="#FBBC04"
                      />
                      <path
                        d="M12.7401 4.74966C14.4509 4.7232 16.1044 5.36697 17.3434 6.54867L20.7695 3.12262C18.6001 1.0855 15.7208 -0.034466 12.7401 0.000808666C8.2029 0.000808666 4.05371 2.55822 2.0166 6.61481L6.00264 9.70575C6.95064 6.86173 9.60947 4.74966 12.7401 4.74966Z"
                        fill="#EA4335"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4204_534">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  &nbsp;Continue with Google
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark w-100"
                  onClick={handleAppleSignIn}
                >
                  <i className="bi bi-apple fs-4" />
                  &nbsp;Continue with Apple
                </button>
                {!confirmResult ? (
                  <>
                    {showMobileLogin ? (
                      <form onSubmit={handleSendCode}>
                        <div className="mb-4">
                          <label
                            htmlFor="mobile"
                            className="form-label form-label-sm"
                          >
                            Mobile number*
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile_number"
                            className={`form-control`}
                            placeholder="Enter mobile number"
                            value={data.mobile_number}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <div id="recaptcha-container"></div>
                        </div>
                        <button
                          type="submit"
                          style={{ backgroundColor: "#0F998E" }}
                          disabled={data.mobile_number.length != 10}
                          className={`btn w-100 text-white mb-3 btn-${data.mobile_number.length === 10 ? "success" : "secondary"}`}
                        >
                          Continue
                        </button>
                      </form>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-dark w-100"
                        onClick={() => setShowMobileLogin(true)}
                      >
                        <i className="bi bi-phone fs-4" />
                        &nbsp;Continue with Phone
                      </button>
                    )}
                  </>
                ) : (
                  <form onSubmit={handleVerifyCode}>
                    <div className="mb-4">
                      <label
                        htmlFor="verification-code"
                        className="form-label form-label-sm"
                      >
                        Verification Code
                      </label>
                      <input
                        id="verification-code"
                        type="text"
                        placeholder="Verification Code"
                        className="form-control"
                        value={verificationCode}
                        onChange={onVerificationCodeChange}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#0F998E" }}
                      className={`btn w-100 text-white mb-3 btn-${verificationCode.length === 6 ? "success" : "secondary"}`}
                      disabled={verificationCode.length != 6}
                    >
                      Verify Code
                    </button>
                  </form>
                )}
              </div>

              <div className="text-center small text-muted">
                By creating an account you agree to our
                <br />
                <a
                  href="https://abillionanalytics.com/privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0F998E" }}
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://abillionanalytics.com/terms-and-condition.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0F998E" }}
                >
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Home);
