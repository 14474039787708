// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// import { initializeApp, cert, getApps } from 'firebase-admin/app';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const serviceAccount = process.env.NEXT_PUBLIC_SERVICE_ACCOUNT!;
const app = initializeApp(firebaseConfig);

// if (!getApps().length) {
//   initializeApp({
//     credential: cert(JSON.parse(serviceAccount)),
//     databaseURL: process.env.FB_DATABASE_URL,
//   });
// }
const auth = getAuth();

export { auth };
